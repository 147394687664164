
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* @font-face {
  font-family: "poppins-bold";  
  src: url("./fonts/Poppins/Poppins-Bold.ttf");
} */

.Overlay {
  position: fixed;
  top: 0;
  padding: 20px;
  left: 0;
  right: 0;
  overflow-y: scroll;
  /* margin: 20px; */
  bottom: 0;
  background-color: rgba(56, 55, 55, 0.6);
}